.collecte {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.checkboxContainer {
    display: flex;
    width: 200px;
    align-items: center;
    gap: 20px;
}

.collecte input {
    width: 50px;
    height: 50px;
}

.quantityContainer {
    width: 200px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    gap: 20px;
}