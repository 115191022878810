.header .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header .title {
    margin: 0;
    flex-grow: 1;
    text-align: center;
}

.header img {
    height: 40px;
    width: 40px;
}