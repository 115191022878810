.datesContainer {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.invoiceContainer {
    margin: 0 10px;
}

.invoiceContainer:nth-child(even) {
    background-color: #f7f5f5;
}

.invoiceContainerSoc {
    display: flex;
    font-size: 8px;
    justify-content: space-between;
    gap: 30px;
    padding: 0 5px;
    line-height: 0;
    border-top: 1px solid #000;

}

.nameContainer {
    flex-grow: 1;
}

.nameContainer p {
    font-weight: 700;
    text-align: center;
}

.invoiceContainerInfo {
    font-size: 8px;
    padding: 0 5px;
}

.lineContainer {
    display: flex;
    justify-content: space-between;
    line-height: 0;
}

.buttonContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    gap: 40px
}

.print {
    display: none
}

.list .printButton {
    height: 40px;
    width: 40px;
    background-color: transparent;
}

.printButton img {
    height: 40px;
    width: 40px;
}



@media print {

    .header {
        display: none
    }

    .datesContainer {
        display: none
    }

    .invoiceContainer:nth-child(even) {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
        background-color: #cacaca;
    }

    .buttonContainer {
        display: none
    }

    .print {
        display: flex
    }

    .pageBreak {
        display: block;
        page-break-before: always;
    }

}