@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:wght@700&display=swap');

body {
  margin: 0;
}

h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 46px;
  color: #222222;
}

p {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

a {
  text-decoration: none;
}

.container {
  margin-left: 5vw;
  margin-right: 5vw;
  max-width: 1200px;
}

.spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #34b1c4;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

button {


  background-color: #34b1c4;
  position: relative;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  border: none;
  padding: 16px 28px;
  border-radius: 6px;
}

h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 20px;
  color: #454545;
}

.flex {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 1200px) {}