.sipnnerContainer {
    display: flex;
    flex-grow: 1;
    align-items: center;
}

li {
    list-style: none;
}

.home input {
    margin: 3vh 0;
    height: 52px;
    color: #111111;
    border: 1px solid #858585;
    background-color: transparent;
    font-size: 20px;
    border-radius: 5px 5px 5px 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 9OO;
    padding: 0 5px;
    flex-grow: 2;
}

.searchContainer {
    display: flex;
    gap: 10px;
}

.loupeContainer {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;

}