.client {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 5vh;
}

.backContainer {
    background-color: #34b1c4;
    border-radius: 6px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backContainer p {
    color: #ffffff;
}

.back {
    margin-top: 50px;
    display: flex;
    gap: 10px
}