.card {
    border-radius: 5px 5px 5px 5px;
    background-color: #f9f9f9;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-bottom: 2px solid #e5e5e5;
    display: flex;
    padding: 10px;
    margin: 10px 0;
    justify-content: space-between;
}

p {
    color: #000000;
    font-weight: 900;
}

.buttonArrow {
    background-color: #34b1c4;
    border-radius: 5px 5px 5px 5px;
    display: flex;
    align-items: center;
}

.buttonArrow p {
    padding: 0 20px;
    color: #ffffff;
}